
import { Component, Mixins, Watch } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import BAEventShare from '@/views/admin/BAEventShare.vue'
import { AppHostnameMixin, AuthMixin, StringsMixin, BAIconsMixin, StripeMixin, VuetifyMixin, AthleteRoutingMixin, QRCodeMixin } from '@/mixins';
import { baEventsStore } from '@/store';
import { BAEventModel, EventParticipantDetails, EventParticipantModel, EventTicketModel } from '@/models/baEvent';
import { BAEventAdminDetail } from '@/../types/constants/admin.routes'
import { DataTableHeader } from 'vuetify';
import { userStore } from '@/store';
import OnboardingDialog from '@/views/onboarding/OnboardingDialog.vue';
import BestAthletesEventPurchaseDialog from '@/components/events/BestAthletesEventPurchaseDialog.vue';
import CopyToClipboard from '@/components/ui/CopyToClipboard.vue';
import { athleteApi } from '@/api/AthleteApi';
import { baPurchaseApi } from '@/api/BAPurchaseApi';
import { AgreementFile } from '@/models/file/FileModel';

@Component({
	components: {
		Page,
        BAEventShare,
        OnboardingDialog,
        BestAthletesEventPurchaseDialog,
	}
})
export default class BAEventDetailPage extends Mixins(VuetifyMixin, AppHostnameMixin, StringsMixin, AuthMixin, BAIconsMixin, StripeMixin, AthleteRoutingMixin, QRCodeMixin) {
    eventLoaded: boolean = false;
    baEvent: BAEventModel = undefined;
    baTicket: EventTicketModel = undefined;
    baParticipant: EventParticipantDetails = undefined;
    qrCode: string = undefined;
    qrCodeUrl: string;

    get IsLoading(): boolean {
        return !this.eventLoaded;
    }

    mounted() {
        if( this.$route.path.includes('public') ) {
            if( userStore.IsReady ) {
                this.gotoRoute(this.RouteToEvent(this.eventId));
                return;
            }
        }
        
        this.mountEvent();
    }

	@Watch('$route.params.eventId') 
    async updateEvent() {
        this.eventLoaded = false;
		await this.loadEvent();
	}

    get eventId(): string {        
        return this.$route.params.eventId;
    }

    async mountEvent() {
        await this.loadEvent();
    }
    async loadEvent(): Promise<BAEventModel> {
        try {
            this.eventLoaded = false;
            this.baEvent = undefined;
            this.baTicket = undefined;
            this.baParticipant = undefined;

            this.baEvent = await baEventsStore.findEvent(this.eventId);
            if( this.IsNotEmpty(this.baEvent) ) {
                this.baTicket = await this.RetrieveTicket();
                if( this.IsNotEmpty(this.baTicket) ) {
                    if( this.baEvent.Online ) {
                        this.qrCode = await this.generateEventQRCode(this.baEvent.meetingUrl, this.baEvent?.id);
                    } else {
                        this.qrCode = await this.generateTicketQRCode(this.baEvent?.id, this.baTicket?.id, this.AthleteId);
                    }
                    const participant = this.baEvent.participants.find(p => p.attendeeId === this.AthleteId);
                    if( participant ) {
                        this.baParticipant = new EventParticipantDetails().load(participant);
                        this.baParticipant.athlete = await athleteApi.findById(participant.attendeeId);
                        this.baParticipant.ticket = await this.baEvent.Ticket(participant.ticketId);
                    } else {
                        this.baParticipant = new EventParticipantDetails().load({
                            attendeeId: this.AthleteId,
                            ticketId: this.baTicket.id,
                            athlete: await athleteApi.findById(this.AthleteId),
                            ticket: this.baTicket
                        });
                    }
                }
            }
            this.eventLoaded = true;
        } catch(e) {
            console.error(`Error loading BA event: `, e);
            return undefined;
        }
    }

    get TicketHeaders(): DataTableHeader<any>[] {
        return [
            {text: 'Name', value: 'name'},
            {text: 'Description', value: 'description'},
			{text: 'Available', width:"100", value: 'Remaining', sortable: false},
			{text: 'Price', width:"150", value: 'Price'},
            {text: '', width:"50", value: 'actions', sortable: false},
            {text: '', value: 'data-table-expand'},
        ]
    }

    get filteredTickets() : EventTicketModel[] {
        return this.baEvent.tickets.filter(t => !t.deleted);
    }

    async RetrieveTicket(): Promise<EventTicketModel> {
        if( this.IsEmpty(this.baEvent) ) return undefined;
        if( !this.AthleteAppActive ) return undefined;

        const purchase = await baPurchaseApi.findPurchase(this.AthleteId, this.baEvent.id);
        if( this.IsEmpty(purchase) ) return undefined;
        if( !purchase.completed ) return undefined;

        const ticket = await this.baEvent.tickets.find(t => t.id === purchase.priceId);
        return ticket;
    }

	get AthleteId(): string {
		if( !this.AthleteAppActive) return undefined;
        if( !userStore.athleteId ) return undefined;

        return userStore.athleteId;
    }

	async generateTicketQRCode(eventId: string, ticketId: string, userId: string): Promise<string> {
        this.qrCodeUrl = `${this.AdminLInk}/baevent-redeem?userId=${userId}&eventId=${eventId}&ticketId=${ticketId}`;
        return await this.generateQRCode(this.qrCodeUrl);
    }
    async generateEventQRCode(meetingUrl: string, eventId: string): Promise<string> {
        this.qrCodeUrl = this.IsNotEmpty(meetingUrl)? `${meetingUrl}/?eventId=${eventId}` : `${this.AthleteAppUrl}/baevent/${eventId}`;
        return await this.generateQRCode(this.qrCodeUrl);
    }

    onEditEvent() {
		this.$router.push({
			name: BAEventAdminDetail,
			params: { eventId: this.eventId }			
		});
    }

    onDownloadEventFile() {
        const link = document.createElement('a');
        link.href = this.baEvent.fileUrl;
        link.download = `${this.baEvent.name}.pdf`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    agreementUnderReview: AgreementFile;
    agreementReview: boolean = false;
    async onReviewAgreement(agreement) {
        this.agreementUnderReview = agreement;
        await this.agreementUnderReview.read();
        this.agreementReview = true;
    }
    onCloseReview() {
        this.agreementReview = false;
    }

}
